import { css } from 'styled-components';

export const notchModalStyle = css`
    .custom-notch {
        position: relative;
        display: flex;
        justify-content: center;

        > div {
            margin-top: 0.5rem;
            height: 6px;
            width: 60px;
            background-color: ${({ theme }) => theme.colors.white};
            opacity: 0.16;
            border-radius: 4px;
        }
    }

    .notch-modal-title {
        margin: 0;
        padding: 0;
        font-size: 1.4rem;
        font-family: ${({ theme }) => theme.typography.font1};
        color: ${({ theme }) => theme.colors.white};
        font-weight: ${({ theme }) => theme.typography.fontWeight[600]};
        letter-spacing: 0.005em;
        text-align: center;
    }

    .notch-modal-text {
        margin: 1rem;
        padding: 0;
        color: ${({ theme }) => theme.colors.lightGrey};
        font-weight: ${({ theme }) => theme.typography.fontWeight[600]};
        font-size: 18px;
        letter-spacing: 0.002em;
        text-align: center;
        font-family: ${({ theme }) => theme.typography.font2};

        > p {
            margin: 0;
            padding: 0;

            > span {
                color: ${({ theme }) => theme.colors.turquoise};
            }
        }
    }
    .different-color-notch-text {
        color: ${({ theme }) => theme.colors.turquoise};
    }

    .stop-screenshare-btn {
        background: ${({ theme }) => theme.colors.orange} !important;
        color: ${({ theme }) => theme.colors.offWhite} !important;
        width: 20% !important;
    }

    .share-screen-btn {
        width: 20% !important;
    }

    .notch-modal-actions {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        > button {
            outline: none;
            border: none;
            padding: 0.8rem;
            width: 80%;
            border-radius: 2.5rem;
            font-size: 18px;
            font-family: ${({ theme }) => theme.typography.font1};
        }

        > .notch-remove-btn {
            color: ${({ theme }) => theme.colors.white};
            background-color: ${({ theme }) => theme.colors.turquoise};
            box-shadow: 0px 10px 16px rgba(74, 208, 238, 0.16);
        }
        > .notch-cancel-btn {
            background-color: transparent;
            border: 2px solid ${({ theme }) => theme.colors.orange};
            color: ${({ theme }) => theme.colors.orange};
        }
    }

    .notch-modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: ${({ theme }) => theme.backgrounds.backdrop};
        display: flex;
        align-items: flex-end;
        justify-content: center;
        z-index: 1001;
    }

    .notch-modal {
        width: 100%;
        background-color: ${({ theme }) => theme.colors.lightTeal};
        border-top-right-radius: 1.5rem;
        border-top-left-radius: 1.5rem;
        width: 100%;
        padding: 20px;
        opacity: 1;
    }

    .notch-modal-content {
        width: 100%;
        padding: 20px;
    }
`;
